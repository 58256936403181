import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

const container = document.getElementById("app");

if (!container) {
  throw new Error("React container #app is missing!");
}

const root = createRoot(container);
root.render(<App />);

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
